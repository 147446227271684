<template>
  <div>
    <v-card flat>
      <v-card-text class="pt-0">
        <v-row
          justify="end"
          align="center"
        >
          <v-col
            v-if="showGrid"
            cols="auto"
            sm="6"
          >
            <v-text-field
              v-model="table.search"
              clearable
              append-icon="mdi-magnify"
              class="input-max-width ml-auto"
              label="Search"
              hide-details
              single-line
            />
          </v-col>
          <v-col
            v-if="showCalendar"
            cols="auto"
          >
            <v-btn
              class="mr-4"
              :color="color"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
              fab
              small
              class="mx-1"
              :color="color"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="mx-1"
              :color="color"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer v-if="showCalendar" />
          <v-col
            v-if="showCalendar"
            cols="auto"
          >
            <v-toolbar-title class="text-h4 mx-4">
              {{ calendarTitle }}
            </v-toolbar-title>
          </v-col>
          <v-spacer v-if="showCalendar" />
          <v-col
            v-if="showCalendar"
            cols="auto"
          >
            <v-btn-toggle
              v-model="mailingStatusFilter"
              dense
              mandatory
              multiple
            >
              <v-btn
                v-for="n in mailingStatues"
                :key="n"
                :value="n"
                :color="$_dashMixin_getMailingIconColor({ status: n })"
                text
              >
                <v-icon>
                  {{ $_dashMixin_getMailingIcon({ discriminator: 'MailingItem', status: n }) }}
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            v-if="showCalendar"
            cols="auto"
          >
            <v-btn-toggle
              v-model="calendar.type"
              dense
              mandatory
              :color="color"
            >
              <v-btn value="day">
                Day
              </v-btn>
              <v-btn value="week">
                Week
              </v-btn>
              <v-btn value="month">
                Month
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            cols="auto"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-select
              v-model="viewMode"
              dense
              hide-details
              :items="viewModeOptions"
              outlined
              label="View Mode"
              class="input-max-width mt-1"
              color="primary"
              @change="changeViewMode"
            />
          </v-col>
        </v-row>
        <v-row class="fill-height">
          <v-col
            v-show="showGrid"
            class="pt-0"
          >
            <v-skeleton-loader
              v-if="isLoading()"
              type="table"
            />
            <v-data-table
              v-else
              item-key="campaignId"
              :headers="computedHeaders"
              :items="items"
              :expanded="table.expanded"
              :custom-filter="tableSearch"
              :search.sync="table.search"
              :custom-sort="tableSort"
              :sort-by="['modifiedOnLocal']"
              sort-desc
              @click:row="toggleExpandRow"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="px-0"
                >
                  <CampaignDashboardExpanded
                    :campaign="item"
                    @update-campaign-mailings="updateCampaignMailings"
                  />
                </td>
              </template>
              <template v-slot:item.campaignName="{ item }">
                <v-tooltip
                  v-if="item.campaignName && item.campaignName.length > 100"
                  top
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{ item.campaignName | truncate(100) }}</span>
                  </template>
                  <span>{{ item.campaignName }}</span>
                </v-tooltip>
                <span v-else>{{ item.campaignName }}</span>
              </template>
              <template v-slot:item.campaignStatusDisplay="{ item }">
                <v-chip
                  :color="$_dashMixin_campaignStatusColor(item)"
                  light
                >
                  {{ item.campaignStatusDisplay }}
                </v-chip>
              </template>
              <template v-slot:item.modifiedOnLocal="{ item }">
                {{ item.modifiedOnLocal | displayDateTimeFormat }}
              </template>
              <template v-slot:item.targetAudienceStatus="{ item }">
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :color="$_dashMixin_taStatusColor(item.targetAudienceStatus)"
                      left
                      v-on="on"
                    >
                      {{ $_dashMixin_getTAIcon(item.targetAudienceStatus) }}
                    </v-icon>
                  </template>
                  <span>{{ item.targetAudienceStatusDisplay || 'Not selected' }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.mailings`]="{ item }">
                <v-tooltip
                  v-for="(mailing) in item.mailings"
                  :key="mailing.id"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :color="$_dashMixin_getMailingIconColor(mailing)"
                      left
                      v-on="on"
                    >
                      {{ $_dashMixin_getMailingIcon(mailing) }}
                    </v-icon>
                  </template>
                  <span>{{ mailing.statusDisplay }}</span>
                </v-tooltip>
                <template v-if="item.numberOfBroadcasts >= item.mailings.length && !item.campaignExpired">
                  <v-tooltip
                    v-for="i in item.numberOfBroadcasts - item.mailings.length"
                    :key="i"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="grey"
                        left
                        v-on="on"
                      >
                        mdi-email-outline
                      </v-icon>
                    </template>
                    <span>Not Created</span>
                  </v-tooltip>
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-if="!item.readOnlyActions">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :disabled="!item.canAddMailings"
                        class="mx-2"
                        color="success"
                        @click="$_dashMixin_addMailing(item)"
                        v-on="on"
                      >
                        mdi-email-plus
                      </v-icon>
                    </template>
                    <span>Add Mailing</span>
                  </v-tooltip>
                  <v-menu
                    right
                    x-offset
                    origin="right"
                    transition="slide-x-transition"
                  >
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-icon
                            :disabled="item.campaignStatus !== 1"
                            class="mr-2"
                            color="error"
                            v-on="{ ...onMenu, ...onTooltip }"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                        <span>Cancel Campaign</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-text class="pa-5">
                        <span>Are you sure you want to cancel this campaign? No scheduled mailings will be sent.</span>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="tertiary"
                          :disabled="isLoading()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="error"
                          :loading="isLoading()"
                          @click="item = $_dashMixin_cancelCampaign(item)"
                        >
                          Yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        color="info"
                        @click="copyCampaign(item)"
                        v-on="on"
                      >
                        mdi-content-copy
                      </v-icon>
                    </template>
                    <span>Copy Campaign</span>
                  </v-tooltip>
                </template>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="mr-2"
                      color="info"
                      @click="$_dashMixin_campaignHistory(item.campaignId)"
                      v-on="on"
                    >
                      mdi-book-open-page-variant
                    </v-icon>
                  </template>
                  <span>Campaign History</span>
                </v-tooltip>
                <v-tooltip
                  v-if="item.campaignStatus === $_campaignStatus_Completed && item.campaignFinished && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
                  top
                >
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on: onMenu, attrs }">
                        <v-icon
                          class="mr-2"
                          color="info"
                          v-bind="attrs"
                          v-on="{ ...onTooltip, ...onMenu }"
                        >
                          mdi-chart-box
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(item.targetAudienceFileTypeId)">
                          <v-list-item-title>
                            <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: item.campaignId } }">
                              Campaign Heatmap
                            </router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                          <v-list-item-title>
                            <router-link :to="{ name: 'CampaignReport', params: { campaignId: item.campaignId } }">
                              Campaign Report
                            </router-link>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <span>Reporting</span>
                </v-tooltip>
                <v-tooltip
                  v-if="isMMSAdmin"
                  top
                >
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-menu
                      v-model="item.adminDetailsMenu"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :close-on-click="false"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-icon
                          color="info"
                          class="mr-2"
                          v-on="{ ...onMenu, ...onTooltip }"
                        >
                          mdi-information
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-text class="px-5 py-2">
                          <LabelValue
                            label="Campaign Id"
                            :value="item.campaignId"
                          />
                          <LabelValue
                            label="Target Id"
                            :value="item.targetAudienceId"
                          />
                          <v-divider />
                          <div
                            v-for="(mailing, i) in item.mailings"
                            :key="`${mailing.id}-${i}`"
                          >
                            <LabelValue
                              :label="`Mailing Id ${mailing.sequence}`"
                              :value="mailing.id"
                            />
                            <LabelValue
                              v-for="(split, s) in mailing.splits"
                              :key="split.id"
                              :label="`Split Id ${$_splitMixin_splitLetters[s]}`"
                              :value="split.id"
                            />
                            <v-divider />
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            @click="item.adminDetailsMenu = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </template>
                  <span>Admin Details</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-icon
                      v-if="item.mailings && item.mailings.length > 0"
                      style="cursor: pointer;"
                      v-on="{ ...onTooltip }"
                    >
                      mdi-chevron-{{ item._expandedIcon }}
                    </v-icon>
                  </template>
                  <span>See details</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-show="showCalendar">
            <v-sheet min-height="600">
              <v-skeleton-loader
                v-if="isLoading()"
                type="date-picker"
              />
              <v-calendar
                v-show="!isLoading()"
                ref="calendar"
                v-model="calendar.focus"
                class="mb-6"
                :color="color"
                :events="mailingsToEvents"
                :event-color="getEventColor"
                :event-margin-bottom="3"
                :now="calendar.today"
                :type="calendar.type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @mouseenter:event="mouseEnterEvent"
                @mouseleave:event="mouseLeaveEvent"
                @change="updateRange"
              />
              <v-menu
                v-model="calendar.selectedOpen"
                :close-on-content-click="false"
                :close-on-click="false"
                :activator="calendar.selectedElement"
                offset-x
              >
                <v-card
                  v-if="calendar.selectedEvent && calendar.selectedEvent.targetAudience && calendar.selectedEvent.campaign && calendar.selectedEvent.mailing && calendar.selectedEvent.mailing.splits"
                  color="grey lighten-4"
                  min-width="400px"
                  flat
                >
                  <v-toolbar
                    :color="calendar.selectedEvent.color"
                    dark
                  >
                    <v-toolbar-title v-html="calendar.selectedEvent.name" />
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <span
                          v-if="calendar.selectedEvent.mailing.splits && calendar.selectedEvent.mailing.splits.length === 1"
                          class="font-weight-bold"
                        >
                          {{ calendar.selectedEvent.mailing.splits[0].subject }}
                        </span>
                        <span v-else>
                          <v-tooltip
                            v-for="(split, i) in calendar.selectedEvent.mailing.splits"
                            :key="split.id"
                            top
                          >
                            <template v-slot:activator="{ on }">
                              <v-progress-linear
                                :style="`width: ${split.distribution}%`"
                                striped
                                height="20"
                                value="100"
                                :color="$_splitMixin_splitColors[i]"
                                v-on="on"
                              >
                                <template v-slot="{ value }">
                                  <strong>{{ split.distribution }}%</strong>
                                </template>
                              </v-progress-linear>
                            </template>
                            <span>{{ split.subject || `Subject ${$_splitMixin_splitLetters[i]}` }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-progress-linear
                                :style="`width: ${calendar.selectedEvent.mailing.winningDistribution}%`"
                                striped
                                height="20"
                                buffer-value="100"
                                :color="$_splitMixin_winningColor"
                                v-on="on"
                              >
                                <template v-slot="{ value }">
                                  <strong>{{ calendar.selectedEvent.mailing.winningDistribution }}%</strong>
                                </template>
                              </v-progress-linear>
                            </template>
                            <span>{{ $_splitMixin_winningCriteriaSelect.find(x => x.value === calendar.selectedEvent.mailing.winningCriteria) ? $_splitMixin_winningCriteriaSelect.find(x => x.value === calendar.selectedEvent.mailing.winningCriteria).text : 'TBD' }}</span>
                          </v-tooltip>
                        </span>
                        <div v-if="calendar.selectedEvent.mailing.modifiedOnLocal">
                          Modified: {{ calendar.selectedEvent.mailing.modifiedOnLocal | displayDateTimeFormat }} by {{ calendar.selectedEvent.mailing.modifiedBy }}
                        </div>
                        <div v-if="calendar.selectedEvent.mailing.winningCriteria">
                          Winning Criteria: {{ `${$_splitMixin_winningCriteriaSelect.find(x => x.value === calendar.selectedEvent.mailing.winningCriteria).text} (${calendar.selectedEvent.mailing.winningDistribution}%)` }}
                        </div>
                        <div v-if="calendar.selectedEvent.mailing.resendType">
                          Resend Type: {{ $_splitMixin_resendTypeSelect.find(x => x.value === calendar.selectedEvent.mailing.resendType).text }}
                        </div>
                        <div class="pb-2">
                          <v-btn
                            v-for="(split, i) in calendar.selectedEvent.mailing.splits"
                            :key="split.id"
                            icon
                            :color="$_splitMixin_splitColors[i]"
                            @click="getSplitHtmlDialog(split.id, calendar.selectedEvent.mailing.id)"
                          >
                            <span
                              class="title"
                            >
                              {{ $_splitMixin_indexToChar(i) }}
                            </span>
                          </v-btn>
                          <v-chip
                            v-if="calendar.selectedEvent.mailing.scheduleDate && calendar.selectedEvent.mailing.isScheduledStatus"
                            :color="$_dashMixin_scheduleDateColor($_dashMixin_dateToLocal(calendar.selectedEvent.mailing.scheduleDate), calendar.selectedEvent.mailing, calendar.selectedEvent.campaign)"
                            class="ml-4"
                            dark
                          >
                            {{ $_dashMixin_dateToLocal(calendar.selectedEvent.mailing.scheduleDate) | displayDateTimeFormat }}
                          </v-chip>
                          {{ calendar.selectedEvent.mailing.winningDistribution > 0 && calendar.selectedEvent.mailing.winningScheduleDate && calendar.selectedEvent.mailing.isScheduledStatus ? ' / ' : '' }}
                          <v-chip
                            v-if="calendar.selectedEvent.mailing.winningDistribution > 0 && calendar.selectedEvent.mailing.winningScheduleDate && calendar.selectedEvent.mailing.isScheduledStatus"
                            :color="$_dashMixin_scheduleDateColorWinner($_dashMixin_dateToLocal(calendar.selectedEvent.mailing.winningScheduleDate), calendar.selectedEvent.mailing, calendar.selectedEvent.campaign)"
                            dark
                          >
                            {{ $_dashMixin_dateToLocal(calendar.selectedEvent.mailing.winningScheduleDate) | displayDateTimeFormat }}
                          </v-chip>
                        </div>
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div v-if="calendar.selectedEvent.targetAudience">
                          <div
                            v-if="calendar.selectedEvent.targetAudience.status"
                            class="pb-1"
                          >
                            <span class="font-weight-bold">Target Audience: </span>
                            <v-tooltip
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  :color="$_dashMixin_taStatusColor(calendar.selectedEvent.targetAudience.status)"
                                  v-on="on"
                                >
                                  {{ $_dashMixin_getTAIcon(calendar.selectedEvent.targetAudience.status) }}
                                </v-icon>
                              </template>
                              <span>{{ calendar.selectedEvent.targetAudience.statusDisplay || 'Not selected' }}</span>
                            </v-tooltip>
                            <span v-if="!calendar.selectedEvent.campaign.readOnlyActionss && showTAActions">
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    class="mr-2"
                                    :color="calendar.selectedEvent.targetAudience.status === $_taStatus_Draft ? 'success' : 'info'"
                                    @click="$_dashMixin_nowwEditLink(calendar.selectedEvent.targetAudience)"
                                    v-on="on"
                                  >
                                    {{ $_dashMixin_getTAIcon(calendar.selectedEvent.targetAudience.status) }}
                                  </v-icon>
                                </template>
                                <span>{{ calendar.selectedEvent.targetAudience.status === $_taStatus_Draft ? 'Edit' : 'View' }} Target Audience</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    color="info"
                                    @click="$_dashMixin_nowwCopyLink(calendar.selectedEvent.targetAudience)"
                                    v-on="on"
                                  >
                                    mdi-content-copy
                                  </v-icon>
                                </template>
                                <span>Copy Target Audience</span>
                              </v-tooltip>
                            </span>
                          </div>
                          <div v-if="calendar.selectedEvent.targetAudience.jobNumber">
                            {{ calendar.selectedEvent.targetAudience.jobNumber }}
                          </div>
                          <div v-if="calendar.selectedEvent.targetAudience.poNumber">
                            {{ calendar.selectedEvent.targetAudience.poNumber }}
                          </div>
                          <div v-if="calendar.selectedEvent.targetAudience.contactName">
                            Contact: {{ calendar.selectedEvent.targetAudience.contactName }}
                          </div>
                          <div v-if="calendar.selectedEvent.targetAudience.orderDateLocal">
                            Order Date: {{ calendar.selectedEvent.targetAudience.orderDateLocal | displayDateFormat }}
                          </div>
                          <div v-if="calendar.selectedEvent.targetAudience.fileType">
                            <v-chip
                              :color="$_dashMixin_fileTypeColor(calendar.selectedEvent.targetAudience.fileType)"
                              :dark="$_dashMixin_fileTypeColorDark(calendar.selectedEvent.targetAudience.fileType)"
                            >
                              {{ calendar.selectedEvent.targetAudience.fileType }}
                            </v-chip>
                          </div>
                          <div v-if="calendar.selectedEvent.targetAudience.count">
                            Count:
                            <v-tooltip
                              v-if="!calendar.selectedEvent.targetAudience.nthRecordSubCount"
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  :color="$_dashMixin_countColor(calendar.selectedEvent.targetAudience.count)"
                                  dark
                                  v-on="on"
                                >
                                  {{ calendar.selectedEvent.targetAudience.count | commaNumber }}
                                </v-chip>
                              </template>
                              <span>{{ $_dashMixin_countSendDaysLabel(calendar.selectedEvent.targetAudience.count) }}</span>
                            </v-tooltip>
                            <v-tooltip
                              v-else
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  :color="$_dashMixin_countColor(calendar.selectedEvent.targetAudience.nthRecordSubCount)"
                                  dark
                                  v-on="on"
                                >
                                  {{ calendar.selectedEvent.targetAudience.nthRecordSubCount | commaNumber }}/{{ calendar.selectedEvent.targetAudience.count | commaNumber }}
                                </v-chip>
                              </template>
                              <span>{{ calendar.selectedEvent.targetAudience.nthRecordSubCount | commaNumber }} Nth record of {{ calendar.selectedEvent.targetAudience.count | commaNumber }} count. {{ $_dashMixin_countSendDaysLabel(calendar.selectedEvent.targetAudience.nthRecordSubCount) }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-divider
                        vertical
                        class="mx-n1"
                      />
                      <v-col cols="6">
                        <div v-if="calendar.selectedEvent.campaign.campaignStatus">
                          <span class="font-weight-bold">Campaign: </span>
                          <v-chip
                            class="mr-2"
                            :color="$_dashMixin_campaignStatusColor(calendar.selectedEvent.campaign)"
                            light
                          >
                            {{ calendar.selectedEvent.campaign.campaignStatusDisplay }}
                          </v-chip>
                          <span v-if="!calendar.selectedEvent.campaign.readOnlyActions">
                            <v-tooltip
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  :disabled="!calendar.selectedEvent.campaign.canAddMailings"
                                  class="mr-2"
                                  color="success"
                                  @click="$_dashMixin_addMailing(calendar.selectedEvent.campaign)"
                                  v-on="on"
                                >
                                  mdi-email-plus
                                </v-icon>
                              </template>
                              <span>Add Mailing</span>
                            </v-tooltip>
                            <v-menu
                              right
                              x-offset
                              origin="right"
                              transition="slide-x-transition"
                            >
                              <template v-slot:activator="{ on: onMenu }">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on: onTooltip }">
                                    <v-icon
                                      :disabled="calendar.selectedEvent.campaign.campaignStatus !== 1"
                                      class="mr-2"
                                      color="error"
                                      v-on="{ ...onMenu, ...onTooltip }"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </template>
                                  <span>Cancel Campaign</span>
                                </v-tooltip>
                              </template>
                              <v-card>
                                <v-card-text class="pa-5">
                                  <span>Are you sure you want to cancel this campaign? No scheduled mailings will be sent.</span>
                                </v-card-text>
                                <v-divider />
                                <v-card-actions>
                                  <v-spacer />
                                  <v-btn
                                    color="tertiary"
                                    :disabled="isLoading()"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="error"
                                    :loading="isLoading()"
                                    @click="calendar.selectedEvent.campaign = $_dashMixin_cancelCampaign(calendar.selectedEvent.campaign)"
                                  >
                                    Yes
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                            <v-tooltip
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="info"
                                  @click="copyCampaign(calendar.selectedEvent.campaign)"
                                  v-on="on"
                                >
                                  mdi-content-copy
                                </v-icon>
                              </template>
                              <span>Copy Campaign</span>
                            </v-tooltip>
                          </span>
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                color="info"
                                class="mr-2"
                                @click="$_dashMixin_campaignHistory(calendar.selectedEvent.campaign.campaignId)"
                                v-on="on"
                              >
                                mdi-book-open-page-variant
                              </v-icon>
                            </template>
                            <span>Campaign History</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="calendar.selectedEvent.campaign.campaignStatus === $_campaignStatus_Completed && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
                            top
                          >
                            <template v-slot:activator="{ on: onTooltip }">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on: onMenu, attrs }">
                                  <v-icon
                                    class="mr-2"
                                    color="info"
                                    v-bind="attrs"
                                    v-on="{ ...onTooltip, ...onMenu }"
                                  >
                                    mdi-chart-box
                                  </v-icon>
                                </template>
                                <v-list>
                                  <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(calendar.selectedEvent.campaign.targetAudienceFileTypeId)">
                                    <v-list-item-title>
                                      <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: calendar.selectedEvent.campaign.campaignId } }">
                                        Campaign Heatmap
                                      </router-link>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                                    <v-list-item-title>
                                      <router-link :to="{ name: 'CampaignReport', params: { campaignId: calendar.selectedEvent.campaign.campaignId } }">
                                        Campaign Report
                                      </router-link>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </template>
                            <span>Reporting</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="isMMSAdmin"
                            top
                          >
                            <template v-slot:activator="{ on: onTooltip }">
                              <v-menu
                                v-model="calendar.selectedEvent.campaign.adminDetailsMenu"
                                right
                                x-offset
                                origin="right"
                                transition="slide-x-transition"
                                :close-on-content-click="false"
                                :close-on-click="false"
                              >
                                <template v-slot:activator="{ on: onMenu }">
                                  <v-icon
                                    color="info"
                                    v-on="{ ...onMenu, ...onTooltip }"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <v-card>
                                  <v-card-text class="px-5 py-2">
                                    <LabelValue
                                      label="Campaign Id"
                                      :value="calendar.selectedEvent.campaign.campaignId"
                                    />
                                    <LabelValue
                                      label="Target Id"
                                      :value="calendar.selectedEvent.campaign.targetAudienceId"
                                    />
                                    <v-divider />
                                    <div
                                      v-for="(mailing, i) in calendar.selectedEvent.mailings"
                                      :key="`${mailing.id}-${i}`"
                                    >
                                      <LabelValue
                                        :label="`Mailing Id ${mailing.sequence}`"
                                        :value="mailing.id"
                                      />
                                      <LabelValue
                                        v-for="(split, s) in mailing.splits"
                                        :key="split.id"
                                        :label="`Split Id ${$_splitMixin_splitLetters[s]}`"
                                        :value="split.id"
                                      />
                                      <v-divider />
                                    </div>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      color="tertiary"
                                      @click="calendar.selectedEvent.campaign.adminDetailsMenu = false"
                                    >
                                      Close
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-menu>
                            </template>
                            <span>Admin Details</span>
                          </v-tooltip>
                        </div>
                        <div v-if="calendar.selectedEvent.campaign.campaignName">
                          {{ calendar.selectedEvent.campaign.campaignName }}
                        </div>
                        <div v-if="calendar.selectedEvent.campaign.onBehalfOfName">
                          Contact: {{ calendar.selectedEvent.campaign.onBehalfOfName }}
                        </div>
                        <div v-if="calendar.selectedEvent.campaign.modifiedBy">
                          Modified by: {{ calendar.selectedEvent.campaign.modifiedBy }}
                        </div>
                        <v-row
                          class="pa-2"
                          justify="center"
                          align="start"
                        >
                          <v-col
                            v-for="(mailing, index) in calendar.selectedEvent.mailings"
                            :key="mailing.id"
                            cols="auto"
                            class="px-0 pb-0"
                          >
                            <v-tooltip
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip v-if="mailing.id === calendar.selectedEvent.mailing.id">
                                  <v-icon
                                    :color="$_dashMixin_getMailingIconColor(mailing)"
                                    large
                                    v-on="on"
                                  >
                                    {{ $_dashMixin_getMailingIcon(mailing) }}
                                  </v-icon>
                                </v-chip>
                                <v-icon
                                  v-else
                                  :color="$_dashMixin_getMailingIconColor(mailing)"
                                  class="ma-1"
                                  v-on="on"
                                >
                                  {{ $_dashMixin_getMailingIcon(mailing) }}
                                </v-icon>
                              </template>
                              <span>{{ mailing.statusDisplay }}</span>
                            </v-tooltip>
                            <template v-if="!calendar.selectedEvent.campaign.readOnlyActions">
                              <template
                                v-if="!mailing.isScheduledStatus"
                              >
                                <br>
                                <v-tooltip
                                  top
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      :disabled="calendar.selectedEvent.campaign.isCanceled"
                                      :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                      color="success"
                                      @click="$_dashMixin_continueMailing(mailing)"
                                      v-on="on"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </template>
                                  <span>Continue Mailing</span>
                                </v-tooltip>
                                <br v-if="mailing.sequence > 1">
                                <v-menu
                                  v-if="mailing.sequence > 1"
                                  right
                                  x-offset
                                  origin="right"
                                  transition="slide-x-transition"
                                >
                                  <template v-slot:activator="{ on: onMenu }">
                                    <v-tooltip
                                      top
                                    >
                                      <template v-slot:activator="{ on: onTooltip }">
                                        <v-icon
                                          :disabled="calendar.selectedEvent.campaign.isCanceled"
                                          :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                          color="error"
                                          v-on="{ ...onMenu, ...onTooltip }"
                                        >
                                          mdi-close
                                        </v-icon>
                                      </template>
                                      <span>Delete Resend</span>
                                    </v-tooltip>
                                  </template>
                                  <v-card>
                                    <v-card-text class="pa-5">
                                      <span>Are you sure you want to delete this resend?</span>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                      <v-spacer />
                                      <v-btn
                                        color="tertiary"
                                        :disabled="isLoading()"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        color="error"
                                        :loading="isLoading()"
                                        @click="deleteResend(calendar.selectedEvent, mailing, index)"
                                      >
                                        Yes
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </template>
                              <br>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    :disabled="!calendar.selectedEvent.campaign.canAddMailings"
                                    :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                    color="info"
                                    @click="$_dashMixin_copyMailing(mailing)"
                                    v-on="on"
                                  >
                                    mdi-content-copy
                                  </v-icon>
                                </template>
                                <span>Add Copy of Mailing</span>
                              </v-tooltip>
                              <br v-if="mailing.isScheduledStatus">
                              <template
                                v-if="mailing.isScheduledStatus"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      :disabled="calendar.selectedEvent.campaign.isCanceled || mailing.status === $_mailingItemStatus_Sent || mailing.status === $_mailingItemStatus_Sending"
                                      :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                      color="warning"
                                      @click="$_dashMixin_unscheduleMailing(mailing)"
                                      v-on="on"
                                    >
                                      mdi-calendar-edit
                                    </v-icon>
                                  </template>
                                  <span>Unschedule Mailing</span>
                                </v-tooltip>
                              </template>
                            </template>
                            <br
                              v-if="mailing.isScheduledStatus && ($_global_hasRouteClaims('MailingResultsDashboard') || (mailing.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')))"
                            >
                            <v-tooltip
                              v-if="mailing.isScheduledStatus && ($_global_hasRouteClaims('MailingResultsDashboard') || (mailing.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')))"
                              top
                            >
                              <template v-slot:activator="{ on: onTooltip }">
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on: onMenu, attrs }">
                                    <v-icon
                                      :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                      color="info"
                                      v-bind="attrs"
                                      v-on="{ ...onTooltip, ...onMenu }"
                                    >
                                      mdi-chart-box
                                    </v-icon>
                                  </template>
                                  <v-list>
                                    <v-list-item v-if="$_global_hasRouteClaims('MailingResultsDashboard')">
                                      <v-list-item-title>
                                        <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId: calendar.selectedEvent.campaign.campaignId, mailingItemId: mailing.id } }">
                                          Real Time Results
                                        </router-link>
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="mailing.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')">
                                      <v-list-item-title>
                                        <router-link :to="{ name: 'SingleMailingReport', params: { campaignId: calendar.selectedEvent.campaign.campaignId, mailingItemId: mailing.id } }">
                                          Single Mailing Report
                                        </router-link>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </template>
                              <span>Reporting</span>
                            </v-tooltip>
                          </v-col>
                          <template v-if="calendar.selectedEvent.campaign.numberOfBroadcasts >= calendar.selectedEvent.mailings.length && !calendar.selectedEvent.campaignExpired">
                            <v-col
                              v-for="i in calendar.selectedEvent.campaign.numberOfBroadcasts - calendar.selectedEvent.mailings.length"
                              :key="i"
                              cols="auto"
                              class="px-0"
                            >
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    color="grey"
                                    class="ma-1"
                                    v-on="on"
                                  >
                                    mdi-email-outline
                                  </v-icon>
                                </template>
                                <span>Not Created</span>
                              </v-tooltip>
                            </v-col>
                          </template>
                          <v-col />
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <!-- <v-spacer /> -->
                    <v-btn
                      color="secondary"
                      @click="closeCalendarEvent"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <bar-chart
                v-if="!isLoading()"
                :chart-data="chartData"
                :options="nowwChartOptions"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="htmlDialog"
      scrollable
      max-width="1400"
    >
      <v-card>
        <v-card-text
          class="pa-5"
          style="max-height: 75vh"
        >
          <MailingPreview
            :split-id="splitIdDialog"
            :mailing-item-id="mailingItemIdDialog"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="closeHtmlDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { dashboardMixin, splitMixin } from '@/shared/mixins/mailing'
import { enumsMixin } from '@/shared/mixins/general'
import { calendarEventFormat } from '@/shared/constants'
import { nowwService, mailingService } from '@/shared/services'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'CampaignTable',

  components: {
    MailingPreview: () => import('@/views/pages/components/mailing/MailingPreview'),
    CampaignDashboardExpanded: () => import('@/views/pages/components/mailing/CampaignDashboardExpanded'),
    LabelValue: () => import('@/components/custom/LabelValue'),
    BarChart: () => import('@/views/pages/components/charts/BarChart')
  },

  mixins: [dashboardMixin, splitMixin, enumsMixin],

  data: (vm) => ({
    items: [],
    color: 'accent',
    viewModeOptions: [
      { text: 'Grid', value: 'grid' },
      { text: 'Calendar', value: 'calendar' }
    ],
    table: {
      search: undefined,
      expanded: [],
      headers: [
        {
          text: 'Campaign',
          value: 'campaignName'
        },
        {
          text: 'Contact',
          value: 'onBehalfOfName'
        },
        {
          text: 'Status',
          value: 'campaignStatusDisplay'
        },
        {
          text: 'Modified',
          value: 'modifiedOnLocal'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Target',
          value: 'targetAudienceStatus'
        },
        {
          text: 'Mailings',
          value: 'mailings'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false
        },
        {
          text: 'Campaign Id',
          value: 'campaignId',
          hidden: true
        }
      ]
    },
    calendar: {
      today: moment().format(calendarEventFormat),
      focus: moment().format(calendarEventFormat), // TODO: remove date '2021-04-08 12:00:00'
      lastRefreshedMonth: moment().month() + 1,
      type: 'month',
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      focusedEventMailingId: null,
      focusedEventCampaignId: null
    },
    mailingStatusFilter: [1, 2, 3, 4, 5, 100],
    mailingStatues: [1, 2, 3, 100, 4, 5],
    chartData: {},
    nowwChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          stacked: true
        }]
      }
    },
    unsubscribe: null,
    newCampaignName: null,
    newCampaignRules: [
      value => !!value || 'Campaign Name is required'
    ],
    menuLoading: false,
    splitIdDialog: null,
    mailingItemIdDialog: null,
    htmlDialog: false
  }),

  created () {
    this.calendar.focus = moment(this.calendarFocusDate).format(calendarEventFormat)
    this.calendar.type = this.calendarViewType
    this.updateDashboard()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER,
        mutationTypes.SET_FILTER_USER_NOWW_ID,
        mutationTypes.SET_DAYS_FROM_NOW,
        mutationTypes.SET_CAMPAIGN_STATUS,
        mutationTypes.SET_DASHBOARD_VIEW_MODE
      ]
      if (listeners.includes(mutation.type)) {
        this.updateDashboard()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['hasClaimKV']),
    showTAActions () {
      return this.hasClaimKV(claims.ITA_DSC)
    },
    viewMode: {
      get () {
        return this.$store.getters.dashboardViewMode()
      },
      set (value) {
        this.$store.dispatch('setDashboardViewMode', value)
      }
    },
    calendarFocusDate: {
      get () {
        return this.$store.getters.calendarFocusDate()
      },
      set (value) {
        this.$store.dispatch('setCalendarFocusDate', value)
      }
    },
    calendarViewType: {
      get () {
        return this.$store.getters.calendarViewType()
      },
      set (value) {
        this.$store.dispatch('setCalendarViewType', value)
      }
    },
    calendarTitle () {
      const { start, end } = this.calendar
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.calendar.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long'
      })
    },
    showGrid () {
      return this.viewMode === 'grid'
    },
    showCalendar () {
      return this.viewMode === 'calendar'
    },
    mailingsToEvents () {
      if (!this.items) return []
      return this.items.reduce((arr, elem) => {
        if (elem.mailings) {
          const { mailings, targetAudience, ...elemSpread } = elem
          let endMinutesFrom = 15
          for (const m of elem.mailings.filter(x => this.mailingStatusFilter.includes(x.status))) {
            let startDate = m.isScheduledStatus ? m.scheduleDate : m.modifiedOn
            let start = moment(this.$_dashMixin_dateToLocal(startDate)).format(calendarEventFormat)
            let end = moment(this.$_dashMixin_dateToLocal(startDate)).add(endMinutesFrom, 'minutes').format(calendarEventFormat)
            let mail = {
              mailing: {
                ...m,
                modifiedOnLocal: this.$_dashMixin_dateToLocal(m.modifiedOn)
              },
              mailings: mailings.map((item) => ({
                id: item.id,
                status: item.status,
                statusDisplay: item.statusDisplay,
                discriminator: item.discriminator,
                sequence: item.sequence,
                name: item.name,
                isScheduledStatus: item.isScheduledStatus,
                splits: item.splits.map((splitItem) => ({
                  id: splitItem.id
                }))
              })),
              targetAudience: { ...targetAudience },
              campaign: { ...elemSpread },
              name: `${elem.campaignName} - ${m.name}`,
              details: `${elem.campaignName} - ${m.name}`,
              start: start,
              end: end,
              color: this.$_dashMixin_getMailingIconColor(m),
              isWinner: false
            }
            arr.push(mail)
            if (m.isScheduledStatus && m.winningScheduleDate) {
              arr.push({
                ...mail,
                name: `${elem.campaignName} - ${m.name} (Winner)`,
                details: `${elem.campaignName} - ${m.name} (Winner)`,
                start: moment(this.$_dashMixin_dateToLocal(m.winningScheduleDate)).format(calendarEventFormat),
                end: moment(this.$_dashMixin_dateToLocal(m.winningScheduleDate)).add(endMinutesFrom, 'minutes').format(calendarEventFormat),
                isWinner: true
              })
            }
          }
        }
        return arr
      }, [])
    },
    customerHeaders () {
      return this.table.headers.filter(x => x.value !== 'accountName')
    },
    computedHeaders () {
      return this.$store.getters['isViewAllCustomers']()
        ? this.table.headers.filter(x => !x.hidden)
        : this.customerHeaders.filter(x => !x.hidden)
    },
    isMMSAdmin () {
      return this.hasClaimKV(claims.MMS_TM)
    }
  },

  methods: {
    updateDashboard () {
      if (this.$store.getters['isViewAllCustomers']()) {
        this.items = []
        return
      }
      this.calendar.selectedOpen = false
      mailingService
        .getCampaignDashboard({
          viewAsCustomer: this.$store.getters['simulatedCustomerCrmId'](),
          days: this.$store.getters['daysFromNow'](),
          filterUser: this.$store.getters['filterUserNowwId'](),
          status: this.$store.getters['campaignStatus'](),
          viewMode: this.$store.getters['dashboardViewMode'](),
          viewModeCalendarStart: moment(this.calendar.focus)
        })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.items = []
          } else {
            this.items = resp.map(x => (
              {
                ...x,
                modifiedOnLocal: moment(this.$_dashMixin_dateToLocal(x.modifiedOn)),
                _expandedIcon: 'down',
                targetAudience: {
                  ...x.targetAudience,
                  orderDateLocal: x.targetAudience && x.targetAudience.orderDate ? moment(this.$_dashMixin_dateToLocal(x.targetAudience.orderDate)) : null
                }// ,
                // mailings: x.mailings.map(m => (
                //   {
                //     ...m,
                //     scheduleDateLocal: m.scheduleDate ? moment(this.$_global_parseAsUtc(m.scheduleDate)).local() : null,
                //     winningScheduleDateLocal: m.winningScheduleDate ? moment(this.$_global_parseAsUtc(m.winningScheduleDate)).local() : null
                //   }
                // ))
              }
            ))
            this.calendar.lastRefreshedMonth = moment(this.calendar.focus).month() + 1
            this.setChartData()
          }
        })
    },
    setChartData () {
      var chartData = {
        labels: [],
        datasets: []
      }
      var dateIterate = moment(this.calendar.start).add(-1, 'M')
      var endDateIterate = dateIterate.clone().endOf(this.calendar.type)// moment(this.calendar.end).add(-1, 'M')
      // console.log(dateIterate.format('MM/DD/YYYY'))
      // console.log(endDateIterate.format('MM/DD/YYYY'))
      while (dateIterate.isSameOrBefore(endDateIterate)) {
        chartData.labels.push(dateIterate.format('M/D'))
        dateIterate.add(1, 'd')
      }
      if (this.mailingsToEvents.length === 0) {
        // chartData.datasets.push(dataset)
        this.chartData = chartData
        return
      }
      var filter = [...new Set(this.mailingsToEvents.filter(x => x.mailing.isScheduledStatus).map(x => x.targetAudience.fileType))]
      filter.forEach((item, i) => {
        var dataset = {
          label: item,
          backgroundColor: this.$_dashMixin_fileTypeColor(item),
          data: []
        }
        var groupedByDay = this.mailingsToEvents.filter(x => x.targetAudience && x.mailing.isScheduledStatus && x.targetAudience.fileType === item)
          .reduce(function (arr, elem) {
            const key = moment(elem.start).format('M/D')
            if (!chartData.labels.includes(key)) {
              return arr
            }
            if (!arr.some(x => x.label === key)) {
              arr.push({
                label: key,
                data: []
              })
            }
            var percOfTA = 1
            if (!elem.isWinner) {
              percOfTA = (100 - elem.mailing.winningDistribution) / 100
            } else {
              percOfTA = elem.mailing.winningDistribution / 100
            }
            // console.log(`arr[${key}].push(${elem.targetAudience.count * percOfTA})`)
            var arrIndex = arr.findIndex(x => x.label === key)
            arr[arrIndex].data.push(elem.targetAudience.trueCount * percOfTA)
            return arr
          }, [])
        chartData.labels.forEach(elem => {
          var sum = 0
          var matchedDay = groupedByDay.find(x => x.label === elem)
          if (matchedDay) {
            matchedDay.data.forEach(obj => {
              sum += obj
            })
          }
          dataset.data.push(sum)
        })
        chartData.datasets.push(dataset)
      })
      this.chartData = chartData
    },
    viewDay ({ date }) {
      this.calendar.focus = date
      this.calendar.type = 'day'
    },
    getEventColor (event) {
      if (this.calendar.focusedEventCampaignId === null ||
      this.calendar.focusedEventCampaignId === event.campaign.campaignId) {
        return event.color
      }
      return 'grey lighten-2'
    },
    setToday () {
      this.calendar.focus = this.calendar.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.calendar.selectedEvent = event
        this.calendar.selectedElement = nativeEvent.target
        this.calendar.focusedEventMailingId = event.mailing.id
        this.calendar.focusedEventCampaignId = event.campaign.campaignId
        setTimeout(() => (this.calendar.selectedOpen = true), 10)
      }

      if (this.calendar.selectedOpen) {
        this.closeCalendarEvent()
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      var prevStart = this.calendar.start
      var prevEnd = this.calendar.end
      this.calendar.start = start
      this.calendar.end = end
      // if (prevStart && prevEnd) {
      //   console.log(`prev range: ${prevStart.date} to ${prevEnd.date}`)
      // }
      // console.log(`new range: ${start.date} to ${end.date}`)
      this.setChartData()
      if (prevStart && prevEnd && this.calendar.lastRefreshedMonth !== start.month && start.month === end.month && (prevStart.month !== start.month || prevEnd.month !== end.month)) {
        this.updateDashboard()
      }
    },
    nth (d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    mouseEnterEvent (event) {
      if (!this.calendar.selectedOpen) {
        this.calendar.focusedEventMailingId = event.event.mailing.id
        this.calendar.focusedEventCampaignId = event.event.campaign.campaignId
      }
    },
    mouseLeaveEvent (event) {
      if (!this.calendar.selectedOpen) {
        this.calendar.focusedEventMailingId = null
        this.calendar.focusedEventCampaignId = null
      }
    },
    closeCalendarEvent () {
      this.calendar.selectedOpen = false
      this.calendar.focusedEventMailingId = null
      this.calendar.focusedEventCampaignId = null
    },
    tableSearch (value, search, item) {
      if (search === null) return true
      if (value === null) return false
      if (value.toString().toLowerCase().includes(search.toLowerCase())) return true
      if (moment.isMoment(value) && this.$options.filters.displayDateTimeFormat(value).includes(search)) return true
      return false
    },
    tableSort (items, index, isDescending) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items
      }
      items.sort((a, b) => {
        if (index[0] === 'campaignStatusDisplay') {
          if (!isDescending[0]) {
            return a.campaignStatus > b.campaignStatus ? 1 : -1
          } else {
            return a.campaignStatus < b.campaignStatus ? 1 : -1
          }
        } else if (index[0] === 'mailings') {
          if (!isDescending[0]) {
            return this.compareMailings(a, b)
          } else {
            return this.compareMailings(b, a)
          }
        } else {
          if (!isDescending[0]) {
            return a[index[0]] > b[index[0]] ? 1 : -1
          } else {
            return b[index[0]] > a[index[0]] ? 1 : -1
          }
        }
      })
      return items
    },
    compareMailings (a, b) {
      if (a.mailings.length !== b.mailings.length) {
        return a.mailings.length > b.mailings.length ? 1 : -1
      }
      for (var i = 0; i < a.mailings.length; i++) {
        if (a.mailings[i].status !== b.mailings[i].status) {
          return a.mailings[i].status > b.mailings[i].status ? 1 : -1
        }
      }
    },
    toggleExpandRow (value) {
      if (!value.mailings || value.mailings.length === 0) return
      if (this.table.expanded.includes(value)) {
        value._expandedIcon = 'down'
        this.table.expanded.splice(this.table.expanded.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.table.expanded.push(value)
      }
    },
    async getSplitHtmlDialog (id, mailingId) {
      this.htmlDialog = true
      this.splitIdDialog = id
      this.mailingItemIdDialog = mailingId
    },
    closeHtmlDialog () {
      this.htmlDialog = false
      this.splitIdDialog = ''
      this.mailingItemIdDialog = ''
    },
    changeViewMode () {
      this.calendar.selectedOpen = false
      this.$emit('changeViewMode', this.viewMode)
    },
    async deleteResend (campaign, mail, index) {
      let campaignModel = await this.$_dashMixin_deleteResend(campaign, mail, index)
      this.updateCampaignMailings(campaignModel)
    },
    updateCampaignMailings (campaign) {
      if (this.showGrid) {
        let existing = this.items.find(x => x.campaignId === campaign.campaignId)
        existing.mailings = campaign.mailings
      } else if (this.showCalendar) {
        this.calendar.selectedOpen = false
        this.updateDashboard()
      }
    },
    async copyCampaign (item) {
      if (this.showCalendar) {
        this.calendar.selectedOpen = false
      }
      await this.$_dashMixin_copyCampaign(item)
      this.updateDashboard()
    }
  },

  watch: {
    'calendar.focus': function (newValue, oldValue) {
      if (newValue) {
        this.calendarFocusDate = moment(newValue)
      }
    },
    'calendar.type': function (newValue, oldValue) {
      if (newValue) {
        this.calendarViewType = newValue
        this.closeCalendarEvent()
      }
    },
    items (newValue, oldValue) {
      this.table.expanded = []
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.job-details.theme--light {
  background-color: #fafafa;
}
.v-data-table.job-details.theme--dark {
  background-color: #424242;
}
.v-data-table.job-details {
  border-left-width: 5px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-left-color: #ffc107;
  border-left-style: solid;
}

.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}
</style>
